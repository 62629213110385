@import "@/assets/scss/main.scss";

.v20-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    gap: 20px;
    width: 100%;

    background: #FFFFFF;
    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .headline {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            /* identical to box height, or 114% */
            color: $elsi-color-schrift-normal;
        }

        .duration {
            color: #7B8B99;
        }
    }

    .info {
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 24px 0px 8px;
        gap: 20px;

        border-top: 2px solid #DAE1E7;

        .icon {
            margin-top: 3px;
            color: #4BB3D4;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
}
