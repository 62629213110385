@import "@/assets/scss/main.scss";

.big-logo {
    position: absolute;
    right: 64px;
    margin-top: 16px;
    width: 200px;
    z-index: 0;
}

section#navigation {
    /* navigation */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 40px;
    gap: 70px;

    left: 0;
    top: 0;
    height: 96px;

    /* Weiß */
    background: #FFFFFF;

    #main-nav {
        /* Main Nav */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 60px;
        justify-content: space-between;

        width: 100%;
        height: 42px;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

        #logo {
            /* logo */
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px;
            gap: 12px;

            width: 218px;
            height: 42px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;

            .icon {
                /* activity */
                width: 24px;
                height: 24px;

                svg {
                    transform: scale(1.4);

                    path {
                        stroke: $elsi-color-blue;
                        stroke-width: .08rem;
                    }
                }
            }

            .text {
                /* ELSI-Tool H&C */
                width: 258px;
                height: 34px;

                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 153.5%;
                /* identical to box height, or 34px */

                /* Blue */
                color: $elsi-color-blue;

                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }

        #optional {
            /* Optional */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;
            gap: 24px;

            width: 522px;
            height: 38px;

            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;

            div {
                box-sizing: border-box;

                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px;
                gap: 16px;

                height: 38px;

                color: #9CA2A6;

                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
    }
}

section#header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 40px;

    background-color: #F8F8F8;

    #hero {
        background: #FFFFFF;
        padding: 50px 90px;

        /* Card */
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        /* Inside auto layout */
        flex: auto;
        order: 0;
        align-self: stretch;

        justify-content: center;
        display: flex;
        column-gap: 1rem;

        #text {
            // 50/50 flexbox
            width: 100%;

            hgroup {
                h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 72.24px;
                    line-height: 80px;
                    color: $elsi-color-schrift-normal;
                    padding-bottom: 1rem;
                }

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24.19px;
                    line-height: 32px;
                    /* or 132% */
                    color: $elsi-color-schrift-normal;

                    padding-bottom: .8rem;
                }
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                /* or 171% */

                color: $elsi-color-schrift-normal;
                padding-bottom: .8rem;
            }
        }

        #image {
            // 50/50 flexbox
            width: 100%;
            display: flex;
            align-self: center;
            justify-content: center;
        }
    }
}

.tool-text {
    height: 65px;
    padding: 10px 20px 20px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.tool-step {
    display: flex;
    flex-direction: column;

    .tool-step-circle {
        border-radius: 50%;
        width: 38px;
        height: 38px;
        padding: 10px;
        background: $elsi-color-backdrop;
        border: none;
        text-align: center;
        font-weight: 700;
    }

}

h6 {
    padding-top: 16px;
    display: flex;
    flex-direction: row;

}

h6:before,
h6:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid $elsi-color-dunkelgrau;
    margin: auto;
}

section#tool-overview {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 32px 40px;

    .tool-column {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .tool-overview {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        background-color: white;
        padding: 40px 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }
}

section#cards {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 32px 40px;

    .cards {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 1.5rem;
    }
}

section#faq {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    padding: 40px 40px calc(32px - 1rem) 40px;

    .faq {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1.5rem;
    }
}

section#ueber-uns {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 32px 40px;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 40px;
        gap: 20px;

        /* Weiß */
        background: #FFFFFF;

        /* Card */
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        .logos {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: calc(40px - 1rem) 0 20px 0;
            gap: 60px;

            .logo,
            .logo img {
                height: 60px;
            }
        }
    }
}

h5 {
    font-weight: 500;
    color: $elsi-color-schrift-normal;
    padding-bottom: 5px;
}


.footer {
    padding-left: 40px;
    padding-bottom: 16px;
}

.version-info {
    color: $elsi-color-dunkelgrau;
    font-size: 10px;
}
