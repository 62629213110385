@import "@/assets/scss/main.scss";

.wrapper {
    margin-bottom: 1rem;
}

.faq-card {
    border: none;
    padding: 0px 16px 0px 16px ;
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;

    width: 100%;

    background: #FFFFFF;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon {
        transform: rotate(180deg);
    }

}
