@import "@/assets/scss/main.scss";

/deep/ .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: .95;
    backdrop-filter: blur(45px);
    display: flex;
    justify-content: center;
    align-items: center;
}

/deep/ .modal {
    margin-top: 2rem;
    box-shadow: none;
}

/deep/ .modal-content {
    background-color: $elsi-color-mittelgrau;
    border-radius: 0;
    border: 0 none;
    padding: 40px;
}

/deep/ .modal-footer {
    padding: 15px;
    display: flex;
    border-radius: 0 !important;
    background-color: white;
}

/deep/ .modal-header {
    padding: 0;
    position: relative;
    border-bottom: 0;
    justify-content: space-between;
    color: $elsi-color-schrift-normal;
}

/deep/ .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
}

/deep/ .modal-body {
    position: relative;
    padding: 20px 10px;
    border: 0 none;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
}

.btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
}
